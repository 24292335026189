








































import { Component, Model, Vue } from "vue-property-decorator";

type Credentials = {
  login: null | string;
  password: null | string;
  remember?: boolean;
};

@Component
export default class LoginForm extends Vue {
  @Model("input", { type: Object }) readonly credentials!: Credentials;

  get showRememberMe() {
    return this.credentials.login && this.credentials.password;
  }
}
